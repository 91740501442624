import { graphql } from "gatsby";
import React from "react";

// Components
import { DoctorHeroSlider, Doctors } from "@components";

const doctors = ({ data }) => {
    const {
        allStrapiDoctor: { nodes: allDoctors },
    } = data;

    const doctors = allDoctors.filter((doctor) => !doctor.sample);

    return (
        <>
            <DoctorHeroSlider />
            {doctors && <Doctors title={"Doctors"} doctors={doctors} />}
        </>
    );
};

export const query = graphql`
    query {
        allStrapiDoctor {
            nodes {
                id
                slug
                name
                bio
                languages
                qualification
                interests
                services {
                    name
                    phone
                    description
                }
                specialization {
                    name
                }
                image {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: DOMINANT_COLOR
                                formats: [AUTO, WEBP, AVIF]
                            )
                            fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    url
                }
                sample
            }
        }
    }
`;

export default doctors;
